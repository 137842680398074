<template>
    <div class="rt-wraper">
        <b-link :to="{name: 'auth-login'}" class="logo">
            <vuexy-logo/>
        </b-link>
        <h1>Terms and Conditions</h1>
        <p>Please read carefully before accessing or downloading any software from this website. This agreement (Licence) is a legal agreement between you (<b>Licensee</b> or <b>you</b>) and the Institute of Accountants and Bookkeepers (company number 01119378) whose registered office is at 110 Bishopsgate, London, England, EC2N 4AY, England, for:</p>
        <ul>
            <li>use of AML Complete version 1.0 computer software and associated services, the data supplied with the software, and the associated media (<b>Software</b>); and</li>
            <li>printed materials and electronic documents (<b>Documents</b>).</li>
        </ul>
        <p>We license use of the Software, services and Documents to you on the basis of this Licence. We do not sell the Software or Documents to you. We remain the owners of the Software and Documents at all times.</p>
        <p><b>Operating System requirements:</b> The Software and use of the service requires the latest supported version of the applicable Microsoft or Apple operating system together with an up to date ‘Javascript’ enabled browser.</p>
        <p>Important notice to all users:</p>
        <ul>
            <li>By clicking on the “accept” button below you agree to the <b>terms</b> of this licence which will bind you and your employees. The <b>terms</b> of this licence include, in particular, limitations on liability in condition 4.1 and condition 5.</li>
            <li>If you do not agree to the <b>terms</b> of this licence, you must click on the “reject” button below and you may not access this <b>software</b> or documents.</li>
        </ul>
        <p>You should print a copy of this Licence for future reference.</p>
        <ol>
            <li>
                <div class="heading">Grant and scope of licence</div>
                <ol>
                    <li>In consideration of payment by you of the [your membership fee] and you agreeing to abide by the terms of this Licence, we grant to you a non-exclusive, non-transferable licence to use the Software, the service and the Documents [in the UK] on the terms of this Licence.</li>
                    <li>
                        <div class="heading">You may:</div>
                        <ol>
                            <li>
                                <div class="heading">download, install and/or use the Software for your internal business purposes only:</div>
                                <ol>
                                    <li>on one central processing unit if the Licence is a single-user licence or the Software is for single use; or</li>
                                    <li>if the Licence is a multi-user or network licence, by the number of concurrent users agreed;</li>
                                </ol>
                            </li>
                            <li>receive and use any update of the Software incorporating “patches” and corrections of errors as may be provided by us from time to time; and</li>
                            <li>use any Documents in support of the use permitted under Condition 1.2 and make up to two copies of the Documents as are reasonably necessary for its lawful use.</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>
                <div class="heading">Restrictions and obligations on you</div>
                <ol>
                    <li>
                        <div class="heading">Except as expressly set out in this Licence or as permitted by any local law, you undertake:</div>
                        <ol>
                            <li>not to copy the Software or Documents except where such copying is incidental to normal use of the Software, or where it is necessary for the purpose operational security;</li>
                            <li>not to rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the <b>Software</b> or Documents;</li>
                            <li>not to make alterations to, or modifications of, the whole or any part of the Software, nor permit the Software or any part of it to be combined with, or become incorporated in, any other programs;</li>
                            <li>
                                <div class="heading">not to disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the <b>Software</b> nor attempt to do any such thing except to the extent that (by virtue of section 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are essential for the purpose of achieving inter-operability of the <b>Software</b> with another <b>software</b> program, and provided that the information obtained by you during such activities:</div>
                                <ol>
                                    <li>is used only for the purpose of achieving inter-operability of the Software with another software program; and</li>
                                    <li>is not unnecessarily disclosed or communicated without our prior written consent to any third party; and</li>
                                    <li>is not used to create any software which is substantially similar to the Software;</li>
                                </ol>
                            </li>
                            <li>to supervise and control use of the Software and ensure that the Software is used by your employees and representatives in accordance with the terms of this Licence;</li>
                            <li>not to provide or otherwise make available the <b>Software</b> in whole or in part (including but not limited to program listings, object and source program listings, object code and source code), in any form to any person other than your employees without prior written consent from us; and</li>
                            <li>to comply with all applicable technology control or export laws and regulations.</li>
                        </ol>
                    </li>
                    <li>
                        <div class="heading">You undertake to:</div>
                        <ol>
                            <li>provide us with all necessary co-operation in relation to this Licence and all necessary access to such information as may be required by the Supplier, in order to provide the Software and services;</li>
                            <li>comply with all applicable laws and regulations with respect to its activities under this Licence;</li>
                            <li>carry out all responsibilities set out in this Licence in a timely and efficient manner;</li>
                            <li>ensure that employees use the services and the Documentation in accordance with the terms and conditions of this Licence and shall be responsible for any breach of this agreement; and</li>
                            <li>obtain and shall maintain all necessary licences, consents, and permissions necessary for us, its contractors and agents to perform our obligations under this Licence.</li>
                        </ol>
                    </li>
                    <li>Under Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017 (<b>Regulations</b>), providers of certain services are required to acquire documented proof of clients identity and to preserve these and other relevant documents for a five-year period following the end of the client business relationship. Whilst the Software provides secure storage, it should not solely be relied upon by you for these purposes. We shall not be held liable where you have failed to ensure you have alternative or duplicate arrangements in place to fulfil you legal responsibilities under the Regulations. You remain ultimately responsible for compliance with the Regulations.</li>
                </ol>
            </li>
            <li>
                <div class="heading">Intellectual property rights</div>
                <ol>
                    <li>You acknowledge that all intellectual property rights in the Software and the Documents anywhere in the world belong to us, that rights in the Software are licensed (not sold) to you, and that you have no rights in, or to, the Software or the Documents other than the right to use them in accordance with the terms of this Licence.</li>
                    <li>You acknowledge that you have no right to have access to the Software in source code form.</li>
                </ol>
            </li>
            <li>
                <div class="heading">Limited warranty</div>
                <ol>
                    <li>We warrant that the Software will, when properly used and on an operating system for which it was designed, perform substantially in accordance with the functions described by us in writing and the services shall be performed by us with reasonable skill and care.</li>
                    <li>
                        <div class="heading">The warranty does not apply:</div>
                        <ol>
                            <li>if the defect or fault in the Software results from you having altered or modified the Software; and</li>
                            <li>if the defect or fault results from you having used the Software in breach of the terms of this Licence or use of the services contrary to our instructions.</li>
                        </ol>
                    </li>
                    <li>
                        <div class="heading">We do not warrant that:</div>
                        <ol>
                            <li>your use of the Software or the services will be uninterrupted or error-free; or</li>
                            <li>that the Services, Documentation and/or the information obtained by the Customer through the Services will meet the Customer's requirements; or</li>
                            <li>the Software or the Services will be free from vulnerabilities or viruses.</li>
                        </ol>
                    </li>
                    <li>We are not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and you acknowledge that the Software, the Services and Documentation may be subject to limitations, delays and other problems inherent in the use of such communications facilities.</li>
                    <li>We shall follow our archiving procedures for the data inputted by you or your employees for the purpose of using the Software and the services (<b>Customer Data</b>) as set out in its Data Archiving Policy, as such document may be amended by us in our sole discretion from time to time. In the event of any loss or damage to Customer Data, your sole and exclusive remedy against us shall be for us to use reasonable commercial endeavours to restore the lost or damaged Customer Data from the latest back-up of such Customer Data maintained by us. We shall not be responsible for any loss, destruction, alteration or disclosure of Customer Data caused by any third party (except those third parties sub-contracted by us to perform services related to Customer Data maintenance and back-up for which we shall remain fully liable).</li>
                </ol>
            </li>
            <li>
                <div class="heading">Limitation of liability</div>
                <ol>
                    <li>You assume sole responsibility for results obtained from the use of the Software, the services and the Documentation by you, and for conclusions drawn from such use. We shall have no liability for any damage caused by errors or omissions in any information, instructions or scripts provided to us by you, or any actions taken by us at your direction.</li>
                    <li>You acknowledge that the Software has not been developed to meet your individual cybersecurity requirements you might be subject to under law or otherwise, and that it is therefore your responsibility to ensure that the facilities and functions of the Software meet your requirements.</li>
                    <li>We only supply the Software, the services and Documents for internal use by your business, and you agree not to use them or any part of them for any re-sale purposes.</li>
                    <li>
                        <div class="heading">We shall not in any circumstances whatever be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with the Licence for:</div>
                        <ol>
                            <li>loss of profits, sales, business, or revenue;</li>
                            <li>business interruption;</li>
                            <li>loss of anticipated savings;</li>
                            <li>wasted expenditure;</li>
                            <li>loss or corruption of data or information;</li>
                            <li>loss of business opportunity, goodwill or reputation;</li>
                            <li>any special, indirect or consequential loss, damage, charges or expenses.</li>
                        </ol>
                    </li>
                    <li>Other than the losses set out in condition 5.4 (for which we are not liable), our maximum aggregate liability under or in connection with this Licence whether in contract, tort (including negligence) or otherwise, shall in all circumstances be limited to a sum equal to 100% of the annual membership fees paid by the Licensee.</li>
                    <li>
                        <div class="heading">Nothing in this Licence shall limit or exclude our liability for:</div>
                        <ol>
                            <li>death or personal injury resulting from our negligence;</li>
                            <li>fraud or fraudulent misrepresentation;</li>
                            <li>any other liability that cannot be excluded or limited by English law.</li>
                        </ol>
                    </li>
                    <li>This Licence sets out the full extent of our obligations and liabilities in respect of the supply of the Software, the services and Documents. Except as expressly stated in this Licence, there are no conditions, warranties, representations or other terms, express or implied, that are binding on us. Any condition, warranty, representation or other term which might otherwise be implied into, or incorporated in, this Licence whether by statute, common law or otherwise, is excluded to the fullest extent permitted by law.</li>
                </ol>
            </li>
            <li>
                <div class="heading">Termination</div>
                <ol>
                    <li>
                        <div class="heading">We may terminate this Licence immediately by written notice to you if:</div>
                        <ol>
                            <li>you commit a material or persistent breach of this Licence which you fail to remedy (if remediable) within 14 days after the service of written notice requiring you to do so; or</li>
                            <li>your membership of the IAB or Practice AML Supervision is terminated for any reason; or</li>
                            <li>you fail to pay to us fees when they are due.</li>
                        </ol>
                    </li>
                    <li>
                        <div class="heading">On termination for any reason:</div>
                        <ol>
                            <li>all rights granted to you under this Licence shall cease;</li>
                            <li>you must immediately cease all activities authorised by this Licence; and</li>
                            <li>you must immediately and permanently cease to access, delete, remove the Software from all computer equipment in your possession, and immediately destroy or return to us (at our option) all copies of the Software and Documents then in your possession, custody or control and, in the case of destruction, certify to us that you have done so.</li>
                        </ol>
                    </li>
                    <li>On termination of this Licence, we may destroy or otherwise dispose of any of the Customer Data in accordance with our Data Archiving Policy as amended from time to time.</li>
                </ol>
            </li>
            <li>
                <div class="heading">Communications between us</div>
                <ol>
                    <li>We may update the terms of this Licence at any time on notice to you in accordance with this condition 7. Your continued use of the Software, services and Documents following the deemed receipt and service of the notice under Condition 7.3 shall constitute your acceptance to the terms of this Licence, as varied. If you do not wish to accept the terms of the Licence (as varied) you must immediately stop using and accessing the Software, the services and Documents on the deemed receipt and service of the notice.</li>
                    <li>If we have to contact you, we will do so by email or by pre-paid post to the address you provided in accordance with your registration for the Software and services, [or where no such details are provided to the email or postal address linked to your membership account].</li>
                    <li>
                        <div class="heading">Note that any notice:</div>
                        <ol>
                            <li>given by us to you will be deemed received and properly served 24 hours after it is first posted on our website, 24 hours after an email is sent, or three days after the date of posting of any letter; and</li>
                            <li>given by you to us will be deemed received and properly served 24 hours after an email is sent, or three days after the date of posting of any letter.</li>
                        </ol>
                    </li>
                    <li>In proving the service of any notice, it will be sufficient to prove, in the case of posting on our website, that the website was generally accessible to the public for a period of 24 hours after the first posting of the notice; in the case of a letter, that such letter was properly addressed, stamped and placed in the post to the address of the recipient given for these purposes; and, in the case of an email, that such email was sent to the email address of the recipient given for these purposes.</li>
                </ol>
            </li>
            <li>
                <div class="heading">Events outside our control</div>
                <ol>
                    <li>We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under this Licence that is caused by an Event Outside Our Control. An Event Outside Our Control is defined below in Condition 8.2.</li>
                    <li>An Event Outside Our Control means any act or event beyond our reasonable control, including without limitation failure of public or private telecommunications networks.</li>
                    <li>
                        <div class="heading">If an Event Outside Our Control takes place that affects the performance of our obligations under this Licence:</div>
                        <ol>
                            <li>our obligations under this Licence will be suspended and the time for performance of our obligations will be extended for the duration of the Event Outside Our Control; and</li>
                            <li>we will use our reasonable endeavours to find a solution by which our obligations under this Licence may be performed despite the Event Outside Our Control.</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>
                <div class="heading">How we may use your personal information</div>
                <ol>
                    <li>Under data protection legislation, we are required to provide you with certain information about who we are, how we process the personal data of those individuals who use the Software, the services and the Documents and for what purposes and those individuals’ rights in relation to their personal data and how to exercise them. This information is provided in the Privacy Notice and it is important that you read that information. (The Privacy Notice is also accessible under the side menu ‘Resources’ tab within AML Complete)</li>
                </ol>
            </li>
            <li>
                <div class="heading">Suspension of access</div>
                <ol>
                    <li>In consideration of payment by you of the [your membership fee] and you agreeing to abide by the terms of this Licence, we grant to you a non-exclusive, non-transferable licence to use the Software, the service and the Documents [in the UK] on the terms of this Licence.</li>
                    <li>
                        <div class="heading">We may suspend access to the Software and services immediately on notice where:</div>
                        <ol>
                            <li>you do not provide sufficiently complete or accurate information in accordance with required profiles and data requirements;</li>
                            <li>you do not submit an up to date data set within 30 days immediately following the registration of your AML Complete account;</li>
                            <li>you do not submit an appropriately revised and up to date data set within the 30 days immediately preceding the date for renewal of your Practice Supervision subscription.</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>
                <div class="heading">Other important terms</div>
                <ol>
                    <li>We may transfer our rights and obligations under this Licence to another organisation, but this will not affect your rights or our obligations under this Licence.</li>
                    <li>You may only transfer your rights or your obligations under this Licence to another person if we agree in writing.</li>
                    <li>This Licence and any document expressly referred to in it constitutes the entire agreement between us and supersedes and extinguishes all previous and contemporaneous agreements, promises, assurances and understandings between us, whether written or oral, relating to its subject matter.</li>
                    <li>You acknowledge that in entering into this Licence you do not rely on and shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in this Licence or any document expressly referred to in it.</li>
                    <li>You agree that you shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in this Licence or any document expressly referred to in it.</li>
                    <li>A waiver of any right or remedy is only effective if given in writing and shall not be deemed a waiver of any subsequent right or remedy.</li>
                    <li>A delay or failure to exercise, or the single or partial exercise of, any right or remedy shall not waive that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy.</li>
                    <li>Each of the conditions of this Licence operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect.</li>
                    <li>This Licence, its subject matter and its formation (and any non-contractual disputes or claims) are governed by English law. We both irrevocably agree to the exclusive jurisdiction of the courts of England and Wales.</li>
                </ol>
            </li>
        </ol>
    </div>
</template>
<script>
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {BLink} from 'bootstrap-vue'
    export default {
        components: {
            BLink,
            VuexyLogo
        }
    }
</script>
<style lang="scss">
    .rt-wraper{padding:30px 40px;display:flex;flex-direction:column;}
    .rt-wraper .logo{align-self:flex-start;margin-bottom:20px;}
    .rt-wraper .logo svg{height:43px;width:160px;}
    .rt-wraper h1{margin:0 0 20px;color:#222;font-size:24px;font-weight:600;}
    .rt-wraper ol{color:#222;}
    .rt-wraper ol li{color:#222;margin-bottom:15px;font-size:18px;line-height:26px;color:#222;font-weight:600;}
    .rt-wraper ol li ol{color:#222;}
    .rt-wraper ol li ol li{color:#222;margin-bottom:10px;font-size:16px;line-height:26px;font-weight:400;}
    .rt-wraper ul{color:#222;}
    .rt-wraper ul li{color:#222;margin-bottom:10px;font-size:16px;line-height:26px;font-weight:400;}
    .rt-wraper p{color:#222;margin-bottom:15px;font-size:16px;line-height:26px;font-weight:400;}
</style>
<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>